import { type ToastContainerProps } from 'react-toastify';

export const TOAST_DEFAULT_PROPS: Partial<ToastContainerProps> = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
};

export const DD_MM_AAAA_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const DD_OF_MM_OF_YYY_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const REGULAR_EXPRESSIONS = {
  hyphen: /-/g,
  nonDigit: /\D/g,
} as const;

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from './context/GlobalContext';
import { type ProfileData } from './context/ProfileDataContext';
import { Routes } from './routes';
import { TOAST_DEFAULT_PROPS } from './utils/constants';

export interface AnalyticsAppProps {
  profileData: ProfileData | null;
}

export const AnalyticsApp = ({ profileData }: AnalyticsAppProps) => {
  return (
    <GlobalContext profileData={profileData}>
      <ToastContainer {...TOAST_DEFAULT_PROPS} />
      <Routes />
    </GlobalContext>
  );
};

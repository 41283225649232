import { type ReactNode } from 'react';

import { DatesRangeContextProvider } from './DatesRangeContext';
import {
  ProfileDataContextProvider,
  type ProfileData,
} from './ProfileDataContext';

interface GlobalContextProps {
  children: ReactNode;
  profileData: ProfileData | null;
}

export const GlobalContext = ({
  children,
  profileData,
}: GlobalContextProps) => {
  return (
    <ProfileDataContextProvider profileData={profileData}>
      <DatesRangeContextProvider>{children}</DatesRangeContextProvider>
    </ProfileDataContextProvider>
  );
};

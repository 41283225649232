/* eslint-disable react/jsx-no-constructed-context-values */
import {
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react';
import { createContext } from 'use-context-selector';

import { getTheRangeDatesBetweenTodayAndLastWeek } from '../helpers/date';

export type DatesRange = {
  startDate: string;
  endDate: string;
};

interface DatesRangeContextData {
  datesRange: DatesRange;
  setDatesRange: Dispatch<SetStateAction<DatesRange>>;
}

export const DatesRangeContext = createContext({} as DatesRangeContextData);

export const DatesRangeContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const defaultDatesRangeValue = getTheRangeDatesBetweenTodayAndLastWeek();
  const [datesRange, setDatesRange] = useState(defaultDatesRangeValue);

  return (
    <DatesRangeContext.Provider
      value={{
        datesRange,
        setDatesRange,
      }}
    >
      {children}
    </DatesRangeContext.Provider>
  );
};
